import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../../components/seo'

const ProductsPage = () => {
  const data = useStaticQuery(graphql`
    query ProductsAbout {
      about: markdownRemark(fields: { parent: { relativeDirectory: { eq: "products/about-rigpro"}, name: {eq: "index"}}}) {
        html
      }
      aboutItems: allMarkdownRemark(
        sort: {fields: fields___parent___name},
        filter: { fields: { parent: { relativeDirectory: { eq: "products/about-rigpro"}, name: {ne: "index"}}}}
      ) {
        nodes {
          html
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const { about, aboutItems } = data
  let isLeft = false

  return (
    <>
      <SEO title="Products" />

      <section className="products-content">
        <div
          dangerouslySetInnerHTML={{ __html: about.html }}
        />

        <div>
          {
            aboutItems.nodes.map((item, index) => {
              const position = (isLeft)? 'left' : 'right'
              
              if (item.frontmatter && item.frontmatter.image) {
                isLeft = !isLeft

                return (
                  <div
                    key={index}
                    className={`benefit-grid__group ${index && 'benefit-grid__group--divider'} benefit-grid__group--${position}`}
                  >
                    <div className="benefit-grid__group-image">
                      <Img fluid={item.frontmatter.image.childImageSharp.fluid} />
                    </div>
                    <div
                      className="benefit-grid__group-content"
                      dangerouslySetInnerHTML={{ __html: item.html }}
                    />
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    className={`benefit-grid__solo ${index && 'benefit-grid__solo--divider'}`}
                    dangerouslySetInnerHTML={{ __html: item.html }}
                  />
                )

              }

            })
          }
        </div>
      </section>
    </>
  )
}

export default ProductsPage